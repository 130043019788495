import { render, staticRenderFns } from "./IconLoader.vue?vue&type=template&id=47e376d8&scoped=true&"
var script = {}
import style0 from "./IconLoader.vue?vue&type=style&index=0&id=47e376d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47e376d8",
  null
  
)

export default component.exports