<template>
  <section class="artists">
    <header class="artists__title-box">
      <h2 class="artists__title-box_title">
        {{ navActive.text ? navActive.text : "Artists we represent" }}
      </h2>
      <div class="artists__title-box_right">
        <PublicSelect :items="sortData" :selected="cardData.sort" @input="sortArtists($event.id)" />
        <FilterBlock
          style="margin-left: 15px"
          :items="filters"
          @open="openFilters = $event"
          @selectFilter="selectFilter"
          @apply="refreshSelectedFilters"
          @clear="refreshSelectedFilters(true)"
        />
      </div>
    </header>
    <div class="artists__nav">
      <BaseButton
        class="artists__nav-button"
        v-for="(item, index) in nav"
        :key="`nav-${index}`"
        :class="{ 'button--white': item.active, 'button--outline-white': !item.active }"
        :text="`${item.text}`"
        @click="clickNav(item.text)"
      />
    </div>
    <IconLoader v-if="loading" class="artists__loader loader--white loader--lg" />
    <template v-else>
      <div v-if="artists.length > 0" class="artists__artists-box">
        <router-link
          v-for="(artist, key) in artists"
          :key="`artist-${key}`"
          :to="{ path: `/artists/${artist.slug}` }"
          v-slot="{ navigate }"
          custom
        >
          <div class="artists__artists-box_card" @click="navigate">
            <!-- <div
              class="image"
              :style="{
                backgroundImage: `url(${artist.search_image}`,
              }"
              @click="$router.push({ path: `/artists/${artist.slug}` })"
            >
              <div class="image__preview"><p>Click to View</p></div>
            </div> -->
            <div class="image-box">
              <img class="image" :src="artist.search_image" :alt="artist.full_name" />
              <div class="hover-container">Click to View</div>
            </div>
            <h3 class="name">{{ artist.full_name }}</h3>
            <span class="country">{{ artist.region }}</span>
          </div>
        </router-link>
      </div>
      <h6 class="exhibition__empty" v-else>NO ARTISTS</h6>
    </template>
    <div v-if="hasMorePages" class="artists__button-box">
      <BaseButton
        class="button button--xs button--white"
        text="LOAD MORE"
        @click.native="loadMore"
      />
    </div>
  </section>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";
import PublicSelect from "@/components/inputs/PublicSelect.vue";
import FilterBlock from "@/components/FilterPublic.vue";
import IconLoader from "@/components/icons/IconLoader.vue";

export default {
  components: {
    BaseButton,
    PublicSelect,
    FilterBlock,
    IconLoader,
  },

  data() {
    return {
      sortData: [
        { id: "last_name_a_z", title: "Last name (A-Z)" },
        { id: "last_name_z_a", title: "Last name (Z-A)" },
      ],
      cardData: {
        sort: "last_name_a_z",
        page: 1,
        region: "",
        market: "",
      },
      nav: [
        {
          active: true,
          text: "Our Artists",
          key: "",
          count: 0,
        },
        {
          active: false,
          text: "Secondary Market",
          key: "secondary",
          count: 0,
        },
        {
          active: false,
          text: "All Artists",
          key: "all",
          count: 0,
        },
      ],
      navActive: {},
      openFilters: false,
      loading: false,
    };
  },
  head: {
    title: function () {
      return {
        inner: "Artists we represent browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: "Artists we represent browngrotta arts",
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: "Artists we represent browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: "Artists we represent browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: "Artists we represent browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: "Description Artists we represent browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: "Artists we represent browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: "Description Artists we represent browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content: `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: "Artists we represent browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  async created() {
    this.loading = true;
    this.navActive = this.nav[0];
    await this.apiGetArtists({
      sort: this.cardData.sort,
      regions: "",
      page: this.cardData.page,
    });
    await this.apiGetFiltersBlock({ list: "regions", type: "content" });

    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Artists",
        to: "/artists",
        clickable: false,
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
    this.loading = false;
  },

  computed: {
    ...mapGetters("publicapi/artists", {
      artists: "getArtists",
      hasMorePages: "getHasMorePages",
    }),
    ...mapGetters("publicapi/filters", {
      filters: "getFilters",
      selectedFilters: "getSelectedFilters",
    }),
  },

  methods: {
    ...mapActions("publicapi/artists", ["apiGetArtists"]),
    ...mapActions("publicapi/filters", ["apiGetFiltersBlock"]),
    ...mapMutations("publicapi/filters", ["updateFilters", "updateSelectedFilters", "clear"]),
    ...mapMutations(["setBreadcrumbs"]),

    async sortArtists(sort) {
      let data = { ...this.cardData, sort, page: 1 };
      let success = await this.apiGetArtists(data);
      if (success) {
        this.cardData = { ...data };
      }
    },

    loadMore() {
      if (this.hasMorePages) {
        this.cardData.page += 1;
        this.apiGetArtists({
          sort: this.cardData.sort,
          page: this.cardData.page,
          regions: this.cardData.region,
          market: this.cardData.market,
        });
      }
    },

    selectFilter(val) {
      this.updateFilters(val);
      if (val.apply) {
        this.refreshSelectedFilters();
      }
    },
    async refreshSelectedFilters(clear) {
      if (clear) {
        await this.clear();
      }
      await this.updateSelectedFilters();
      this.filterTable();
    },
    async filterTable() {
      this.loading = true;
      let data = { ...this.cardData, page: 1 };
      this.selectedFilters.forEach(el => {
        if (el.key === "price") {
          data.price_start = "";
          data.price_end = "";
          el.items.forEach(i => {
            data[i.key] = i.val;
          });
        } else {
          let filters = el.items.map(i => {
            return i.id;
          });
          data[el.key] = filters.join(",");
        }
      });
      let success = await this.apiGetArtists(data);
      this.loading = false;
      if (success) {
        this.cardData = data;
      }
    },
    clickNav(textButton) {
      this.nav.map(item => {
        if (item.text === textButton) {
          item.active = true;
          this.navActive = item;
          this.cardData.market = item.key;
          this.filterTable();
        } else item.active = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 1.8rem;
  line-height: 2.4rem;
  @media screen and (max-width: $xs) {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}

.artists {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 19.7rem;
  padding: 0rem 10.5rem 23.5rem;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 2;
  @media screen and (max-width: $xs) {
    padding: 0rem 2rem 22.1rem;
  }

  &__title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    @media screen and (max-width: $xs) {
    }
    &_right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
    &_title {
      font-size: 4rem;
      line-height: 5rem;
      @media screen and (max-width: $xs) {
        font-size: 3.5rem;
        line-height: 4.3rem;
        margin-bottom: 30px;
      }
    }
  }
  &__empty {
    font-size: 20px;
    font-weight: 600;
    padding: 10px 10px 50px;
    text-align: center;
    text-transform: uppercase;
  }
  &__nav {
    padding: 6rem 0 4rem;
    display: flex;
    @media screen and (max-width: $xs) {
      padding-top: 3rem 0;
      flex-wrap: wrap;
    }
    &-button {
      border-radius: 19px;
      height: 4rem;
      padding: 0 20px;
      margin-right: 10px;
      @media screen and (max-width: $xs) {
        margin-bottom: 10px;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  &__artists-box {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 3rem;
    grid-row-gap: 6.8rem;
    @media screen and (max-width: $md) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: $xs) {
      grid-template-columns: 1fr;
    }

    &_card {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &-link {
        display: block;
        text-decoration: none;
      }

      &:hover {
        .image-box {
          .hover-container {
            opacity: 1;
          }
        }
      }

      .image-box {
        position: relative;

        .image {
          height: 27vw;
          width: 100%;
          object-fit: cover;
          background-size: cover;
          background-position: center;
          border-radius: 4px;
          @media screen and (max-width: $xs) {
            height: 90vw;
          }
        }

        .hover-container {
          position: absolute;
          width: 100%;
          height: 100%;
          font-size: 3rem;
          line-height: 5rem;
          background-color: rgba($color: $blue, $alpha: 0.83);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.3s ease;
          opacity: 0;
          top: 0;
          border-radius: 4px;
        }
      }

      // .image {
      //   position: relative;
      //   height: 27vw;
      //   width: 100%;
      //   background-size: cover;
      //   background-position: center;
      //   margin-bottom: 25px;
      //   border-radius: 4px;
      //   @media screen and (max-width: $xs) {
      //     height: 90vw;
      //   }

      //   &__preview {
      //     position: absolute;
      //     height: 100%;
      //     width: 100%;
      //     display: flex;
      //     align-items: center;
      //     justify-self: center;
      //     opacity: 0;
      //     border-radius: 4px;
      //     transition: all 0.3s ease;

      //     p {
      //       width: 100%;
      //       text-align: center;
      //       font-size: 3rem;
      //       line-height: 1.67;
      //       letter-spacing: -1px;
      //     }
      //   }

      //   &:hover {
      //     .image__preview {
      //       opacity: 1;
      //       background-color: rgba($color: $blue, $alpha: 0.83);
      //     }
      //   }
      // }

      .name {
        font-size: 2.4rem;
        line-height: 3.1rem;
        margin-top: 1.2rem;
        font-weight: 500;
        // &:hover {
        //   text-decoration: underline;
        // }
      }

      .country {
        margin-top: 0.8rem;
        @media screen and (max-width: $xs) {
          font-size: 1.4rem;
          line-height: 2.5rem;
        }
      }
    }
  }

  &__button-box {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-top: 7rem;

    .button {
      min-width: 180px;
    }
  }
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
